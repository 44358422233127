import React from 'react';
import ReactDOM from 'react-dom';
import {
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core/styles';
import Surveys from './Surveys.jsx';
import './intramuros.css';
import { initializeFirebaseApp } from './utils/firebase';

const generateClassName = createGenerateClassName({
  seed: 'survey',
});

export function initSurveysWidget(cityId, aggloId) {
  if (document.getElementById('intramuros_surveys')) {
    console.log('Init IntraMuros Sondages widget');
    ReactDOM.render(
      <StylesProvider generateClassName={generateClassName}>
        <Surveys cityId={cityId} aggloId={aggloId} />
      </StylesProvider>,
      document.getElementById('intramuros_surveys'),
    );
  } else {
    console.log('No tag with id: intramuros_surveys');
  }
}

// Launch widget
console.log('Widget Sondage chargé.');
console.log('TARGET_ENV: ' + process.env.TARGET_ENV);
console.log('NODE_ENV: ' + process.env.NODE_ENV);

const { intramurosCityId, intramurosAggloId, commune, interco } = window;

console.log('City ID = ' + intramurosCityId);
console.log('Agglo ID = ' + intramurosAggloId);

// Firebase
initializeFirebaseApp();

if (intramurosCityId || intramurosAggloId || commune || interco) {
  initSurveysWidget(intramurosCityId || commune, intramurosAggloId || interco);
}
