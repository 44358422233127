import React, { PureComponent } from 'react';
import { Grid, withStyles, Typography, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { split, forEach } from 'lodash';

import Loader from '../utils/Loader';
import { formatDateAll } from '../utils/dates';
import { getSurveyResultAggregated } from '../utils/surveys';

class SurveyResult extends PureComponent {
  state = {
    surveyResultFetched: false,
    fetchResultError: false,
    surveyResults: null,
  };

  computePossiblesAnswers = answers => {
    const possibleAnswers = [];
    if (answers) {
      const options = split(answers, '/');
      forEach(options, option => {
        possibleAnswers.push(option);
      });
    }
    return possibleAnswers;
  };

  createAnswerResultsView = (
    possiblesAnswers,
    length,
    answers,
    totalResponses,
  ) => {
    const table = [];
    const items = [];
    if (possiblesAnswers && answers && totalResponses > 0) {
      for (let i = 0; i < length; i += 1) {
        const answerName = possiblesAnswers[i];
        const answerCount = answers[answerName];
        const answerCountPercent = (answerCount / totalResponses) * 100;
        const percentWidth = answerCountPercent.toFixed(2) + '%';
        items.push(
          <div key={i}>
            <Typography
              align="left"
              style={{ width: '100%', marginTop: 5 }}
              variant="subtitle1"
            >
              {answerName}
              {' : '}
              {answerCountPercent.toFixed(2)}
              {'% ('}
              {answerCount}
              {answerCount > 1 ? ' votes)' : ' vote)'}
            </Typography>
            <div
              style={{
                width: '100%',
                backgroundColor: '#d7d7d7',
                height: 30,
              }}
            >
              <div
                style={{
                  width: percentWidth,
                  backgroundColor: '#90B7F3',
                  height: 30,
                }}
              />
            </div>
          </div>,
        );
      }
      items.push(
        <Typography
          key={'key-total'}
          align="center"
          style={{ width: '100%', marginTop: 5, marginBottom: 5 }}
          variant="subtitle1"
        >
          {'Nombre total de votes : '}
          {totalResponses}
        </Typography>,
      );
    }
    table.push(
      <div key={'key-container'} style={{ width: '100%' }}>
        {items}
      </div>,
    );

    return table;
  };

  updateStateWithSurveyAggregatedresult = surveyResults => {
    if (surveyResults) {
      this.setState({
        surveyResultFetched: true,
        surveyResults,
      });
    } else {
      this.setState({
        surveyResultFetched: true,
      });
    }
  };

  render() {
    const { survey, classes, goToList } = this.props;
    const { surveyResults, fetchResultError, surveyResultFetched } = this.state;

    if (!survey) {
      return (
        <div style={{ padding: 20, width: '100%' }}>
          <Button onClick={() => goToList()} variant="text">
            {'< Retour'}
          </Button>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={0}
            style={{ backgroundColor: 'white', padding: 20 }}
          >
            <Typography
              align="center"
              style={{
                marginTop: 20,
                color: 'black',
                fontWeight: 'bold',
              }}
              variant="subtitle1"
            >
              {"Oups! Le sondage n'a pas été trouvé ..."}
            </Typography>
          </Grid>
        </div>
      );
    }

    if (!surveyResultFetched) {
      console.log('go to call result aggregated for survey id = ' + survey.id);
      getSurveyResultAggregated(
        survey,
        this.updateStateWithSurveyAggregatedresult,
        () =>
          this.setState({ fetchResultError: true, surveyResultFetched: true }),
      );
      return (
        <div style={{ padding: 20, width: '100%' }}>
          <Button onClick={() => goToList()} variant="text">
            {'< Retour'}
          </Button>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={0}
            style={{
              backgroundColor: 'white',
              padding: 20,
            }}
          >
            <Loader />
            <Typography
              align="center"
              style={{
                marginTop: 40,
                marginBottom: 40,
                color: 'black',
                fontWeight: 'bold',
              }}
              variant="subtitle1"
            >
              {'Chargement des résultats du sondage ...'}
            </Typography>
          </Grid>
        </div>
      );
    }

    if (fetchResultError) {
      return (
        <div style={{ padding: 20, width: '100%' }}>
          <Button onClick={() => goToList()} variant="text">
            {'< Retour'}
          </Button>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={0}
            style={{ backgroundColor: 'white', padding: 20 }}
          >
            <Typography
              align="center"
              style={{
                marginTop: 40,
                marginBottom: 40,
                color: 'black',
                fontWeight: 'bold',
              }}
              variant="subtitle1"
            >
              {'Impossible de récupérer les résultats du sondage.'}
            </Typography>
          </Grid>
        </div>
      );
    }

    const possiblesAnswers1 = this.computePossiblesAnswers(survey.answer1);
    const possiblesAnswers2 = this.computePossiblesAnswers(survey.answer2);
    const possiblesAnswers3 = this.computePossiblesAnswers(survey.answer3);
    const possiblesAnswers4 = this.computePossiblesAnswers(survey.answer4);
    const possiblesAnswers5 = this.computePossiblesAnswers(survey.answer5);

    return (
      <div style={{ padding: 20, width: '100%' }}>
        <Button onClick={() => goToList()} variant="text">
          {'< Retour'}
        </Button>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={0}
          style={{
            backgroundColor: 'white',
            padding: 20,
          }}
        >
          <Grid item xs={10} sm={8} md={6} lg={5} style={{ width: '100%' }}>
            <Typography
              align="center"
              className={classes.titleText}
              variant="h5"
            >
              {survey.title}
            </Typography>
            <Typography
              align="center"
              className={classes.endDateText}
              variant="subtitle2"
            >
              {'Terminé le '}
              {formatDateAll(survey.end_date)}
            </Typography>
            {survey.image ? (
              <img
                src={survey.image}
                alt={survey.title}
                style={{
                  width: '100%',
                  maxHeight: 300,
                  objectFit: 'contain',
                  marginTop: 15,
                }}
              />
            ) : null}
            {survey.description ? (
              <Typography
                align="left"
                className={classes.descriptionText}
                variant="body2"
              >
                {survey.description}
              </Typography>
            ) : null}
            {survey.question1 ? (
              <Typography
                align="left"
                className={classes.questionText}
                variant="body1"
              >
                {survey.question1}
              </Typography>
            ) : null}
            {survey.answer1 && surveyResults
              ? this.createAnswerResultsView(
                  possiblesAnswers1,
                  possiblesAnswers1.length,
                  surveyResults.answer1,
                  surveyResults.answer1TotalResponses,
                )
              : null}
            {survey.question2 ? (
              <Typography
                align="left"
                className={classes.questionText}
                variant="body1"
              >
                {survey.question2}
              </Typography>
            ) : null}
            {survey.answer2 && surveyResults
              ? this.createAnswerResultsView(
                  possiblesAnswers2,
                  possiblesAnswers2.length,
                  surveyResults.answer2,
                  surveyResults.answer2TotalResponses,
                )
              : null}
            {survey.question3 ? (
              <Typography
                align="left"
                className={classes.questionText}
                variant="body1"
              >
                {survey.question3}
              </Typography>
            ) : null}
            {survey.answer3 && surveyResults
              ? this.createAnswerResultsView(
                  possiblesAnswers3,
                  possiblesAnswers3.length,
                  surveyResults.answer3,
                  surveyResults.answer3TotalResponses,
                )
              : null}
            {survey.question4 ? (
              <Typography
                align="left"
                className={classes.questionText}
                variant="body1"
              >
                {survey.question4}
              </Typography>
            ) : null}
            {survey.answer4 && surveyResults
              ? this.createAnswerResultsView(
                  possiblesAnswers4,
                  possiblesAnswers4.length,
                  surveyResults.answer4,
                  surveyResults.answer4TotalResponses,
                )
              : null}
            {survey.question5 ? (
              <Typography
                align="left"
                className={classes.questionText}
                variant="body1"
              >
                {survey.question5}
              </Typography>
            ) : null}
            {survey.answer5 && surveyResults
              ? this.createAnswerResultsView(
                  possiblesAnswers5,
                  possiblesAnswers5.length,
                  surveyResults.answer5,
                  surveyResults.answer5TotalResponses,
                )
              : null}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = () => ({
  questionText: {
    marginTop: 20,
    marginBottom: 5,
    color: '#003057',
    fontWeight: 'bold',
  },
  titleText: {
    color: '#003057',
    fontWeight: 'bold',
  },
  endDateText: {
    color: '#868686',
    marginTop: 5,
  },
  descriptionText: {
    marginTop: 20,
    whiteSpace: 'pre-line',
  },
});

SurveyResult.propTypes = {
  classes: PropTypes.objectOf(PropTypes.object()).isRequired,
  survey: PropTypes.objectOf(PropTypes.object()).isRequired,
  goToList: PropTypes.objectOf(PropTypes.object()).isRequired,
};

export default withStyles(styles)(SurveyResult);
