import firebase from 'firebase/app';
import 'firebase/auth';

export const config = {
  apiKey: 'AIzaSyD9PGhmQfysKhVRRQnbzInMb53QKOMctGw',
  authDomain: 'intramuros-184518.firebaseapp.com',
  databaseURL: 'https://intramuros-184518.firebaseio.com',
  projectId: 'intramuros-184518',
  storageBucket: 'intramuros-184518.appspot.com',
  messagingSenderId: '363972675705',
};

export const getUserIdToken = () => {
  try {
    if (typeof window !== "undefined") {
      return firebase.auth().currentUser
        ? firebase.auth().currentUser.uid
        : null;
    }
    return null;
  } catch (error) {
    console.error('error retrieving uid token firebase: ' + error);
    return null;
  }
};

export const initializeFirebaseApp = () => {
  if (!firebase.apps.length) {
    console.log('Initialize firebase instance only ONCE !!');
    firebase.initializeApp(config);
  }
};

export const signInAnonymously = callback => {
  firebase
    .auth()
    .signInAnonymously()
    .then(() => {
      console.log(
        'user authentified, uid = ' + firebase.auth().currentUser.uid,
      );
      if (callback) {
        callback(firebase.auth().currentUser.uid);
      }
    });
};
