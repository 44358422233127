import React, { PureComponent } from 'react';
import {
  Grid,
  withStyles,
  Typography,
  Paper,
  CardActionArea,
  CircularProgress,
  Button,
  Input,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { split, forEach } from 'lodash';

import Loader from '../utils/Loader';
import { getUserIdToken, signInAnonymously } from '../utils/firebase';
import { isSurveyInProgress, calculateEndingSurvey } from '../utils/dates';
import config from '../config';
import { successMessage, errorMessage } from '../utils/message';
import { getSurveyResult } from '../utils/surveys';

class SurveyDetails extends PureComponent {
  state = {
    token: getUserIdToken(),
    surveyResultFetched: false,
    fetchResultError: false,
    surveyResultId: null,
    answer1: null,
    answer2: null,
    answer3: null,
    answer4: null,
    answer5: null,
    freeAnswer: '',
    surveyIsSending: false,
  };

  componentDidMount() {
    const { state } = this;
    if (!state.token) {
      signInAnonymously((token) => {
        if (!state.token) {
          this.setState({ token });
        }
      });
    }
  }

  updateStateWithSurveyresult = (surveyResult) => {
    if (surveyResult && surveyResult.id) {
      this.setState({
        surveyResultId: surveyResult.id,
        surveyResultFetched: true,
        answer1: surveyResult.answer1,
        answer2: surveyResult.answer2,
        answer3: surveyResult.answer3,
        answer4: surveyResult.answer4,
        answer5: surveyResult.answer5,
        freeAnswer: surveyResult.freeAnswer ? surveyResult.freeAnswer : '',
      });
    } else {
      this.setState({
        surveyResultFetched: true,
      });
    }
  };

  refreshStateFromSurveyResult = (responseBody) => {
    if (responseBody) {
      this.setState({
        surveyResultId: responseBody.id,
        answer1: responseBody.answer1,
        answer2: responseBody.answer2,
        answer3: responseBody.answer3,
        answer4: responseBody.answer4,
        answer5: responseBody.answer5,
        freeAnswer: responseBody.freeAnswer ? responseBody.freeAnswer : '',
        surveyIsSending: false,
      });
    } else {
      this.setState({ surveyIsSending: false });
    }
  };

  computeRadioProps = (answers) => {
    const radioProps = [];
    if (answers) {
      const options = split(answers, '/');
      let i = 0;
      forEach(options, (option) => {
        const radioProp = {
          label: option,
          value: i,
        };
        i += 1;
        radioProps.push(radioProp);
      });
    }
    return radioProps;
  };

  generateButtons = (radioProps, stateAnswer, updateState) => {
    return radioProps.map((button) => {
      const isSelected = stateAnswer === button.label;
      return (
        <Paper
          square
          key={button.value}
          style={{
            width: '100%',
            backgroundColor: isSelected ? '#1baae1' : 'white',
          }}
        >
          <CardActionArea
            onClick={() => updateState(button.label)}
            title={button.label}
          >
            <Typography
              align="center"
              style={{
                color: isSelected ? 'white' : 'black',
                // fontWeight: "bold",
              }}
              variant="subtitle1"
            >
              {button.label}
            </Typography>
          </CardActionArea>
        </Paper>
      );
    });
  };

  errorSending = () => {
    this.setState({ surveyIsSending: false });
  };

  sendSurveyResponse = (id) => {
    const { state } = this;
    const { goToList } = this.props;
    if (!state.token) {
      errorMessage(
        'Impossible de vous identifier. Si le problème persiste, contactez le support IntraMuros (support@intramuros.org).',
      );
      return;
    }
    this.setState({ surveyIsSending: true });
    let url = config.apiRoot + config.apiSurveyResults;
    if (state.surveyResultId) {
      url += state.surveyResultId + '/';
    }
    const method = state.surveyResultId ? 'PATCH' : 'POST';

    const data = new FormData();
    if (method === 'POST') {
      data.append('survey', id);
    }
    if (state.answer1) {
      data.append('answer1', state.answer1);
    }
    if (state.answer2) {
      data.append('answer2', state.answer2);
    }
    if (state.answer3) {
      data.append('answer3', state.answer3);
    }
    if (state.answer4) {
      data.append('answer4', state.answer4);
    }
    if (state.answer5) {
      data.append('answer5', state.answer5);
    }
    if (state.freeAnswer) {
      data.append('freeAnswer', state.freeAnswer);
    }

    data.append('token', state.token || '');
    data.append('origin', 'web');

    fetch(url, {
      method,
      body: data,
    })
      .then((response) => {
        if (response.ok) {
          return response;
        }
        const error = new Error(response.statusText);
        error.response = response;
        return Promise.reject(error);
      })
      .then((r) => r.json())
      .then((json) => {
        this.refreshStateFromSurveyResult(json);
        successMessage('Merci. Votre vote a été enregistré.');
        if (goToList) {
          goToList();
        }
      })
      .catch((error) => {
        this.errorSending();
        const response = error ? error.response : null;
        if (response && response.status) {
          if (response.status === 429) {
            errorMessage(
              'Envoi bloqué ! Pour des raisons de sécurité vous ne pouvez pas voter plus de 10 fois par heure.\n Nous vous invitons a réessayer dans 1h maximum.',
            );
          } else {
            errorMessage(
              'Erreur (code ' +
                response.status +
                " ) ! Veuillez essayer à nouveau. Si le problème persiste, vérifiez votre connexion internet ou contactez le support IntraMuros (support@intramuros.org) en précisant le code d'erreur.",
            );
          }
        } else {
          errorMessage(
            "Impossible d'envoyer votre réponse. Veuillez essayer à nouveau. Si le problème persiste, vérifiez votre connexion internet ou contactez le support IntraMuros (support@intramuros.org).",
          );
        }
      });
  };

  render() {
    const { classes, survey, goToList } = this.props;
    const {
      surveyResultFetched,
      fetchResultError,
      token,
      answer1,
      answer2,
      answer3,
      answer4,
      answer5,
      freeAnswer,
      surveyIsSending,
      surveyResultId,
    } = this.state;

    if (!survey) {
      return null;
    }

    const surveyInProgress = isSurveyInProgress(survey);

    if (surveyInProgress) {
      if (!surveyResultFetched && token !== null) {
        console.log(
          'go to call result survey api for survey id = ' +
            survey.id +
            ' and token = ' +
            token,
        );
        getSurveyResult(survey, token, this.updateStateWithSurveyresult, () =>
          this.setState({ fetchResultError: true, surveyResultFetched: true }),
        );

        return (
          <div style={{ padding: 20, width: '100%' }}>
            <Button onClick={() => goToList()} variant="text">
              {'< Retour'}
            </Button>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={0}
              style={{
                backgroundColor: 'white',
                padding: 20,
              }}
            >
              <Loader />
              <Typography
                align="center"
                style={{
                  marginTop: 40,
                  marginBottom: 40,
                  color: 'black',
                  fontWeight: 'bold',
                }}
                variant="subtitle1"
              >
                {'Chargement du sondage ...'}
              </Typography>
            </Grid>
          </div>
        );
      }

      if (fetchResultError) {
        return (
          <div style={{ padding: 20, width: '100%' }}>
            <Button onClick={() => goToList()} variant="text">
              {'< Retour'}
            </Button>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={0}
              style={{
                backgroundColor: 'white',
                padding: 20,
              }}
            >
              <Typography
                align="center"
                style={{
                  marginTop: 40,
                  marginBottom: 40,
                  color: 'black',
                  fontWeight: 'bold',
                }}
                variant="subtitle1"
              >
                {'Erreur de chargement'}
              </Typography>
            </Grid>
          </div>
        );
      }

      const radioProps1 = this.computeRadioProps(survey.answer1);
      const radioProps2 = this.computeRadioProps(survey.answer2);
      const radioProps3 = this.computeRadioProps(survey.answer3);
      const radioProps4 = this.computeRadioProps(survey.answer4);
      const radioProps5 = this.computeRadioProps(survey.answer5);

      return (
        <div
          style={{
            padding: 20,
            width: '100%',
          }}
        >
          <Button onClick={() => goToList()} variant="text">
            {'< Retour'}
          </Button>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={0}
            style={{
              backgroundColor: 'white',
              padding: 20,
            }}
          >
            <Grid item xs={10} sm={8} md={7} lg={6} style={{ width: '100%' }}>
              <Typography
                align="center"
                className={classes.titleText}
                variant="h5"
              >
                {survey.title}
              </Typography>
              <Typography
                align="center"
                className={classes.endDateText}
                variant="subtitle2"
              >
                {'Fin du vote '}
                {calculateEndingSurvey(survey.end_date)}
              </Typography>
              {survey.image ? (
                <img
                  src={survey.image}
                  alt={survey.title}
                  style={{
                    width: '100%',
                    maxHeight: 300,
                    objectFit: 'contain',
                    marginTop: 15,
                  }}
                />
              ) : null}
              {survey.description ? (
                <Typography
                  align="left"
                  className={classes.descriptionText}
                  variant="body2"
                >
                  {survey.description}
                </Typography>
              ) : null}
              {survey.question1 ? (
                <Typography
                  align="left"
                  className={classes.questionText}
                  variant="body1"
                >
                  {survey.question1}
                </Typography>
              ) : null}
              {survey.answer1 && radioProps1
                ? this.generateButtons(radioProps1, answer1, (label) =>
                    this.setState({ answer1: label }),
                  )
                : null}
              {survey.question2 ? (
                <Typography
                  align="left"
                  className={classes.questionText}
                  variant="body1"
                >
                  {survey.question2}
                </Typography>
              ) : null}
              {survey.answer2 && radioProps2
                ? this.generateButtons(radioProps2, answer2, (label) =>
                    this.setState({ answer2: label }),
                  )
                : null}
              {survey.question3 ? (
                <Typography
                  align="left"
                  className={classes.questionText}
                  variant="body1"
                >
                  {survey.question3}
                </Typography>
              ) : null}
              {survey.answer3 && radioProps3
                ? this.generateButtons(radioProps3, answer3, (label) =>
                    this.setState({ answer3: label }),
                  )
                : null}
              {survey.question4 ? (
                <Typography
                  align="left"
                  className={classes.questionText}
                  variant="body1"
                >
                  {survey.question4}
                </Typography>
              ) : null}
              {survey.answer4 && radioProps4
                ? this.generateButtons(radioProps4, answer4, (label) =>
                    this.setState({ answer4: label }),
                  )
                : null}
              {survey.question5 ? (
                <Typography
                  align="left"
                  className={classes.questionText}
                  variant="body1"
                >
                  {survey.question5}
                </Typography>
              ) : null}
              {survey.answer5 && radioProps5
                ? this.generateButtons(radioProps5, answer5, (label) =>
                    this.setState({ answer5: label }),
                  )
                : null}
              {survey.freeQuestion ? (
                <Typography
                  align="left"
                  className={classes.questionText}
                  variant="body1"
                >
                  {survey.freeQuestion}
                </Typography>
              ) : null}
              {survey.freeQuestion ? (
                <Input
                  placeholder="Votre réponse ..."
                  style={{
                    borderWidth: 1,
                    borderColor: '#E9E9E9',
                    flexWrap: 'wrap',
                    padding: 10,
                    width: '100%',
                    textAlign: 'left',
                    color: '#727272',
                    minHeight: 100,
                  }}
                  multiline
                  rowsMax={4}
                  onChange={(event) =>
                    this.setState({ freeAnswer: event.target.value })
                  }
                  value={freeAnswer}
                />
              ) : null}
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={0}
              >
                <Button
                  onClick={() => {
                    if (!surveyIsSending) {
                      this.sendSurveyResponse(survey.id);
                    }
                  }}
                  variant="contained"
                  style={{
                    marginTop: 35,
                    backgroundColor: '#1baae1',
                    color: 'white',
                    borderRadius: 0,
                  }}
                >
                  {surveyIsSending ? (
                    <CircularProgress style={{ width: 20, height: 20 }} />
                  ) : (
                    <div>{surveyResultId ? 'Mettre à jour' : 'Valider'}</div>
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }
    return null;
  }
}

const styles = () => ({
  questionText: {
    marginTop: 20,
    marginBottom: 5,
    color: '#003057',
    fontWeight: 'bold',
  },
  titleText: {
    color: '#003057',
    fontWeight: 'bold',
  },
  endDateText: {
    color: '#868686',
    marginTop: 5,
  },
  descriptionText: {
    marginTop: 20,
    whiteSpace: 'pre-line',
  },
});

SurveyDetails.propTypes = {
  classes: PropTypes.objectOf(PropTypes.object()).isRequired,
  survey: PropTypes.objectOf(PropTypes.object()).isRequired,
  goToList: PropTypes.objectOf(PropTypes.object()).isRequired,
};

export default withStyles(styles)(SurveyDetails);
