import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, CardContent, Card, Typography } from '@material-ui/core';
import {
  calculateEndingSurvey,
  isSurveyInProgress,
  formatDateAll,
} from '../utils/dates';

class SurveyCard extends PureComponent {
  render() {
    const { classes, survey, userHasAnswered, goToDetail } = this.props;

    if (!survey) return null;

    const surveyInProgress = isSurveyInProgress(survey);
    const textToVote = userHasAnswered ? 'Changer mon vote' : 'Voter';

    return (
      <Card className={classes.card}>
        <Grid
          direction="column"
          justify="center"
          alignItems="center"
          container
          style={{ backgroundColor: 'rgba(235,235,235, 0.5)' }}
        >
          <Typography
            align="center"
            className={classes.titleText}
            component="div"
            variant="h5"
          >
            {survey.title}
          </Typography>
        </Grid>

        <CardContent className={classes.content} title={survey.title}>
          <Grid
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            container
          >
            <Typography className={classes.descriptionText}>
              {survey.description}
            </Typography>
          </Grid>
          <Grid
            direction="column"
            justify="center"
            alignItems="center"
            container
          >
            <Typography className={classes.dateText} component="div">
              {surveyInProgress
                ? 'Fin du vote ' + calculateEndingSurvey(survey.end_date)
                : 'Terminé le ' + formatDateAll(survey.end_date)}
            </Typography>

            {!surveyInProgress && survey.onlyFreeQuestion ? null : (
              <Button
                variant="text"
                style={{
                  display: 'inline-block',
                  marginTop: 10,
                  backgroundColor: surveyInProgress ? '#71c386' : '#1baae1',
                  color: 'white',
                  borderRadius: 0,
                  width: 200,
                }}
                onClick={() => goToDetail(survey)}
              >
                <Typography className={classes.buttonText} component="div">
                  {surveyInProgress ? (
                    <div>{textToVote}</div>
                  ) : (
                    <div> {'Voir les résultats'}</div>
                  )}
                </Typography>
              </Button>
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

SurveyCard.propTypes = {
  classes: PropTypes.objectOf(PropTypes.object()).isRequired,
  survey: PropTypes.objectOf(PropTypes.object()).isRequired,
  userHasAnswered: PropTypes.bool,
  goToDetail: PropTypes.func,
};

const styles = () => ({
  card: {
    marginTop: 0,
    marginBottom: 25,
    marginLeft: 25,
    marginRight: 25,
    borderRadius: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    borderColor: 'rgba(0, 0, 0, 0.1)',
    borderStyle: 'solid',
    width: '100%',
    borderWidth: 1,
  },
  content: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    margin: 0,
    backgroundColor: 'white',
  },
  titleText: {
    fontWeight: 'bolder',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
  descriptionText: {
    whiteSpace: 'pre-line',
  },
  buttonText: {
    color: 'white',
  },
  dateText: {
    marginTop: 20,
    fontStyle: 'italic',
    color: '#868686',
  },
});

export default withStyles(styles)(SurveyCard);
