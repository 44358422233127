import config from '../config';

export const getSurveyResult = (survey, token, callback, errorCallback) => {
  if (survey && survey.id) {
    fetch(
      config.apiRoot +
        config.apiSurveyResults +
        '?survey-id=' +
        survey.id +
        `&token=` +
        token,
    )
      .then(response => {
        if (response.ok) {
          return response;
        }
        const error = new Error(response.statusText);
        error.response = response;
        return Promise.reject(error);
      })
      .then(res => res.json())
      .then(item => callback(item[0]))
      .catch(() => {
        errorCallback();
      });
  } else {
    console.log('error: survey id is null !');
    errorCallback();
  }
};

export const getSurveyResultAggregated = (survey, callback, errorCallback) => {
  if (survey && survey.id) {
    fetch(
      config.apiRoot +
        config.apiSurveyResultsAggregated +
        '?survey-id=' +
        survey.id,
    )
      .then(response => {
        if (response.ok) {
          return response;
        }
        console.log(response);
        const error = new Error(response.statusText);
        error.response = response;
        return Promise.reject(error);
      })
      .then(response => response.json())
      .then(surveyResult => callback(surveyResult[0]))
      .catch(() => {
        errorCallback();
      });
  } else {
    console.log('error: survey id is null !');
    errorCallback();
  }
};
