import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, Grid, Typography } from '@material-ui/core';
import { orderBy, map, isEmpty } from 'lodash';
import request from 'superagent';
import Notifications from 'react-notify-toast';

import SurveyCard from './components/SurveyCard';
import { muiTheme, defaultThemeOption } from './utils/tools';
import Loader from './utils/Loader';
import config from './config';
import { getUserIdToken, signInAnonymously } from './utils/firebase';
import SurveyDetails from './components/SurveyDetails';
import SurveyResult from './components/SurveyResult';
import { isSurveyInProgress } from './utils/dates';

const list = 'list';
const detail = 'detail';

class Surveys extends Component {
  constructor(props) {
    super(props);
    this.state = { fetched: false, page: list };
  }

  componentDidMount() {
    const { cityId, aggloId } = this.props;
    if (!getUserIdToken()) {
      signInAnonymously();
    }

    if (cityId || aggloId) {
      this.fetchSurveys(cityId, aggloId);
    }
  }

  fetchSurveys(cityId, aggloId) {
    request
      .get(config.apiRoot + config.apiSurveys)
      .query({ 'city-id': cityId })
      .query({ 'agglo-id': aggloId })
      .then(res => res.body)
      .then(items => {
        this.setState({
          items: orderBy(items, ['updated_at'], ['desc']),
          fetched: true,
        });
      })
      .catch(err => {
        this.setState({ error: true });
        console.log('error during call : ' + err);
      });
  }

  goToDetail = surveyDetails => {
    this.setState({ page: detail, surveyDetails });
  };

  goToList = () => {
    this.setState({ page: list, surveyDetails: null });
  };

  generateListCards = items => {
    return map(items, survey => (
      <SurveyCard
        survey={survey}
        key={survey.id}
        goToDetail={this.goToDetail}
      />
    ));
  };

  generateSurveyDetailPage = surveyDetails => {
    if (isSurveyInProgress(surveyDetails)) {
      return <SurveyDetails survey={surveyDetails} goToList={this.goToList} />;
    }
    return <SurveyResult survey={surveyDetails} goToList={this.goToList} />;
  };

  render() {
    const { items, error, fetched, page, surveyDetails } = this.state;

    const customTypography = window.forceFont
      ? { fontFamily: ['Lato', 'sans-serif'].join(',') }
      : {};

    const themeOptions = {
      ...defaultThemeOption,
      typography: customTypography,
    };

    const theme = muiTheme(themeOptions);
    console.log(theme);

    if (isEmpty(items)) {
      if (error || fetched) {
        return (
          <MuiThemeProvider theme={theme}>
            <div style={{ padding: 20 }}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={0}
                style={{ backgroundColor: 'white', padding: 20 }}
              >
                <Typography
                  align="center"
                  style={{
                    marginTop: 40,
                    marginBottom: 40,
                    fontWeight: 'bold',
                  }}
                  variant="subtitle1"
                >
                  {'Aucun sondage.'}
                </Typography>
              </Grid>
            </div>
          </MuiThemeProvider>
        );
      }
      return (
        <MuiThemeProvider theme={theme}>
          <div style={{ padding: 20 }}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={0}
              style={{ padding: 20 }}
            >
              <Loader />
              <Typography
                align="center"
                style={{
                  marginTop: 20,
                  color: 'black',
                  fontWeight: 'bold',
                }}
                variant="subtitle1"
              >
                {'Chargement ...'}
              </Typography>
            </Grid>
          </div>
        </MuiThemeProvider>
      );
    }

    return (
      <MuiThemeProvider theme={theme}>
        <Notifications options={{ zIndex: 5000 }} />
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          style={{
            width: '100%',
            paddingLeft: 25,
            paddingRight: 25,
          }}
          spacing={0}
        >
          <Grid
            container
            spacing={0}
            style={{
              maxWidth: 860,
            }}
          >
            {page === list
              ? this.generateListCards(items)
              : this.generateSurveyDetailPage(surveyDetails)}
          </Grid>
        </Grid>
      </MuiThemeProvider>
    );
  }
}

Surveys.propTypes = {
  cityId: PropTypes.number,
  aggloId: PropTypes.number,
};

export default Surveys;
