import { notify } from 'react-notify-toast';

const show = notify.createShowQueue();

export const successMessage = (msg) => {
  console.log(msg);
  return show(msg, 'success', 4000, {
    background: '#0E1717',
    text: '#FFFFFF',
  });
};

export const errorMessage = (msg) => {
  console.log(msg);
  return show(msg, 'error', 5000);
};
